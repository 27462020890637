 // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDq3Q2WZOhWd0ofFs5uKCBpt3kAzKBEWAE",

    authDomain: "pentagon-11200.firebaseapp.com",
  
    projectId: "pentagon-11200",
  
    storageBucket: "pentagon-11200.appspot.com",
  
    messagingSenderId: "596590493272",
  
    appId: "1:596590493272:web:6d5fc53ec7b50b146c142d",
  
    measurementId: "G-QQG40EM753"


};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export { auth, db, firebaseConfig, storage }