import logo from './logo.svg';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import TrackingDetails from './components/TrackingDetails';
import React, { useState } from 'react';
import { collection, deleteDoc, limit, increment, collectionGroup, getDocs, getDoc, doc, setDoc, updateDoc, onSnapshot, serverTimestamp, query, where, addDoc, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import Footer from './components/Footer';

function App() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [id, setId] = useState("");

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };

  const handleShow = () => setShow(true);

  const trackProduct = async (trackingId) => {
    console.log("id", trackingId);
    setLoading(true);

    getDoc(doc(db, 'Products', trackingId))
      .then(results => {
        setProductDetails(results.data());
        console.log("results");
        setShow(true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <div>
     
      <div className="container d-flex flex-column align-items-center">
        <div className="d-flex my-4 align-items-center">
          <a href="#"><img src="logo.png" alt="logo" className="img-fluid" /></a>
          <p className="m-2 gradient-text fs-1" style={{ fontSize: 18 }}>Pentagons Freight</p>
        </div>
        <div className="form-box border-top border-2 border-primary p-3 w-100" style={{ maxWidth: '400px' }}>
          <div className="logo d-flex justify-content-center align-items-center mb-3">
            <p className="fw-bold">Track and Trace status of your shipment</p>
          </div>
          <input
            value={id}
            onChange={e => setId(e.target.value)}
            type="text"
            placeholder="Enter Tracking ID"
            className="form-control mb-3"
          />
          <button
            className="w-100 shadow rounded btn btn-primary"
            onClick={() => trackProduct(id)}
          >
            {loading ? 'TRACKING...' : 'TRACK'}
          </button>
        </div>
      </div>
      <TrackingDetails show={show} handleClose={handleClose} product={productDetails} />


    </div>
  );
}

export default App;
