import React, { useState, useEffect, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Player } from '@lottiefiles/react-lottie-player';
import { APIProvider, Map, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

function TrackingDetails({ handleClose, show, product }) {
    const [productStatus, setProductStatus] = useState({});
    const [assetLink, setAssetLink] = useState("");

    const statuses = [
        { label: 'Storage', value: 0, icon: 'pe-7s-box2' },
        { label: 'Confirmed Order', value: 1, icon: 'pe-7s-note2' },
        { label: 'Processing Order', value: 2, icon: 'pe-7s-config' },
        { label: 'Quality Check', value: 3, icon: 'pe-7s-medal' },
        { label: 'Product Dispatched', value: 4, icon: 'pe-7s-car' },
        { label: 'Product Delivered', value: 5, icon: 'pe-7s-home' },
    ];

    useEffect(() => {
        const exist = statuses.find((item) => Number(item.value) === Number(product?.status));
        if (exist) setProductStatus(exist);
    }, [product]);

    useEffect(() => {
        const links = [
            "https://lottie.host/f8cdc5b6-595c-4134-9b6c-105285d0a446/9NyTf9lIR7.json",
            "https://lottie.host/8c9aa5dd-d6b9-4793-b655-b205db17898c/L0EftTNHCp.json",
            "https://lottie.host/c23564c4-36cf-4394-a556-2ec4f9e3043f/nXHSK6OnxB.json",
            "https://lottie.host/682439e0-a99c-46de-8af6-6346750d4cb5/uVYHJrOTmM.json",
            "https://lottie.host/f1b63b35-fa23-40de-a196-764f9d1c7163/cZvo2tSwrT.json"
        ];
        setAssetLink(links[product?.status] || "");
    }, [productStatus]);

    const defaultProps = {
        center: {
            lat: product?.coordinates.lat || 0.262144,
            lng: product?.coordinates?.lng || 32.535347
        },
        zoom: 10
    };

    const MyComponent = () => {
        const geocodingLib = useMapsLibrary('geocoding');
        const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);
        useEffect(() => {
            if (!geocoder) return;
            // use geocoder.geocode here
        }, [geocoder]);
        return (
            <Player controls={false} autoplay loop src={'https://lottie.host/de7f1c72-470a-40ad-97e7-10c67aa9258e/6oSH6op0zF.json'} style={{ width: 100, height: 100 }} />
        );
    };

    const [comment,setComment] = useState()
    useEffect(()=>{
        if(productStatus){
            product?.productStatuses.map((res)=>{
                console.log(productStatus,res.name)
                if(productStatus.value === res.name){
                    setComment(res.description)
                }
            })
        }
       
    },[productStatus])

    return (
        <Modal fullscreen show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>TRACKING ORDER NO - {product?.productId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="card mb-3">
                        <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary text-white text-center">
                            <div className="w-100 py-1 px-2"><span className="text-medium">Shipped Via:</span> Pentagons Freight</div>
                            <div className="w-100 py-1 px-2"><span className="text-medium">Status:</span> {productStatus?.label}</div>
                        </div>
                        <div className="card-body">
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                {statuses.map((status, index) => (
                                    <div key={index} className={`step ${productStatus.value >= status.value ? 'completed' : ''}`}>
                                        <div className="step-icon-wrap">
                                            <div className="step-icon"><i className={status.icon}></i></div>
                                        </div>
                                        <h4 className="step-title">{status.label}</h4>
                                    </div>
                                ))}
                            </div>
                            <div className='mx-2 my-4 pb-4 rounded shadow-lg'>

                                <div className='bg-black px-4 py-2'>
                                    <h5 className='text-white'>Product Details</h5>
                                </div>
                                <div class="alert alert-success" role="alert">
                             
                                    {productStatus?.label}: {comment}
                                </div>
                                <div className='d-flex align-items-end justify-content-end p-4'>
                                    <a href={String(product?.downloadUrl)} target="_blank" rel="noopener noreferrer" download>
                                        <Button style={{ backgroundColor: "#1BAEF0", border: "none" }} className="px-4 py-2">DOWNLOAD PDF</Button>
                                    </a>
                                </div>

                                <div className='w-100 d-flex flex-wrap'>
                                    <div className='w-100 w-md-50 d-flex flex-column justify-content-center align-items-center'>
                                        <Player controls={false} autoplay loop src={assetLink} style={{ height: 'auto', width: '100%' }} />
                                        {productStatus.value === 4 && (
                                            <div style={{ height: '400px', width: '100%' }} className="mt-4">
                                                <APIProvider apiKey={'YOUR_GOOGLE_MAPS_API_KEY'}>
                                                    <Map defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom}>
                                                        <Marker position={defaultProps.center}><MyComponent /></Marker>
                                                    </Map>
                                                </APIProvider>
                                            </div>
                                        )}
                                    </div>
                                    <div className='w-100 w-md-50'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Product Name</p>
                                                <p className='text-sm fw-bold'>{product?.productName}</p>
                                            </div>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Location</p>
                                                <p className='text-sm fw-bold'>{product?.productLocation}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Customer Name</p>
                                                <p className='text-sm fw-bold'>{product?.customerName}</p>
                                            </div>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Customer Email</p>
                                                <p className='text-sm fw-bold'>{product?.customerEmail}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Quantity</p>
                                                <p className='text-sm fw-bold'>{product?.productQuantity}</p>
                                            </div>
                                            <div className='rounded w-100 w-md-50 shadow-lg m-2 p-2'>
                                                <p className='text-lg'>Date</p>
                                                <p className='text-sm fw-bold'>{new Date(product?.date).toLocaleDateString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <i>This product is shipped and protected under <strong>Pentagons Freight</strong></i>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TrackingDetails;
